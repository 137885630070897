import { useState } from "react";
import { WaterList } from "./WaterList";
import { useMap } from "react-leaflet/lib/hooks";
import { useDebounce } from "usehooks-ts";
import { Position } from "../domain/Position";
import L from "leaflet";

interface IWaterDrawerProps {
  position: Position;
}
export function WaterDrawer({ position }: IWaterDrawerProps) {
  const [center, setCenter] = useState<Position>(position);
  const map = useMap();
  const [areaSize, setAreaSize] = useState<number>(getMapAreaSize(map));
  const debouncedCenter = useDebounce<Position>(center, 500);
  const debouncedAreaSize = useDebounce<number>(areaSize, 500);

  map.addEventListener("dragend", () => {
    setCenter(map.getCenter());
  });

  map.addEventListener("zoomend", () => {
    setAreaSize(getMapAreaSize(map));
  });

  return <WaterList position={debouncedCenter} areaSize={debouncedAreaSize} />;
}
function getMapAreaSize(map: L.Map): number {
  const center = map.getCenter();
  const northEastBound = map.getBounds().getNorthEast();

  const distCenterNorthEast = center.distanceTo(northEastBound);

  return Math.ceil(distCenterNorthEast);
}
