import { createRoot } from "react-dom/client";
import { App } from "./App";
import { StrictMode } from "react";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";

const API_URL = "https://api.getwatertogo.org/graphql";

const client = new ApolloClient({
  uri: API_URL,
  cache: new InMemoryCache(),
});

const domNode = document.getElementById("app") as Element;
const root = createRoot(domNode);
root.render(
  <StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </StrictMode>
);
