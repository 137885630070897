import { useQuery } from "@apollo/client";
import { WaterPositionMarker } from "./WaterPositionMarker";
import { Position } from "../domain/Position";
import { GetWaterResponse, WATER_QUERY } from "../graphql/water";

interface IWaterListProps {
  position: Position;
  areaSize: number;
}
export function WaterList({ position, areaSize }: IWaterListProps) {
  const { data } = useQuery<GetWaterResponse>(WATER_QUERY, {
    variables: { lat: position.lat, lng: position.lng, distance: areaSize },
  });

  return data && data.water ? (
    <div>
      {data.water.map((w) => (
        <WaterPositionMarker position={w} key={w.id} />
      ))}
    </div>
  ) : null;
}
