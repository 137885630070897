import { LatLngExpression, divIcon } from "leaflet";
import { Marker } from "react-leaflet/lib/Marker";

interface IWaterPositionMarkerProps {
  position: LatLngExpression;
}

export function WaterPositionMarker({ position }: IWaterPositionMarkerProps) {
  const positionMarkerIcon = divIcon({
    html: '<i class="bi-droplet-fill"></i>',
    className: "water-marker-icon",
  });

  return <Marker position={position} icon={positionMarkerIcon}></Marker>;
}
