import { gql } from "@apollo/client";

export const WATER_QUERY = gql`
  query GetWater($lat: Float!, $lng: Float!, $distance: Int!) {
    water(lat: $lat, lng: $lng, distance: $distance) {
      id
      lat
      lng
      picture
    }
  }
`;

export interface GetWaterResponse {
  water: GqlWater[];
}

export interface GqlWater {
  id: string;
  lat: number;
  lng: number;
}
