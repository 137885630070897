import { useState, useEffect } from "react";
import { MapContainer } from "react-leaflet/lib/MapContainer";
import { TileLayer } from "react-leaflet/lib/TileLayer";
import { LocationDrawer } from "./components/LocationDrawer";
import { WaterDrawer } from "./components/WaterDrawer";
import { Position } from "./domain/Position";
import { Col, Container, Row } from "react-bootstrap";

export function App() {
  const [userPosition, setUserPosition] = useState<Position>();

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        setUserPosition({
          lat: coords.latitude,
          lng: coords.longitude,
        });
      },
      (err) => {
        console.error(err);
      }
    );
  }, []);

  return (
    <Container fluid={true}>
      <Row>
        <Col className="p-0">
          <MapContainer className="vh-100" center={[0, 0]} zoom={2}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              detectRetina={true}
            />

            {userPosition ? <LocationDrawer position={userPosition} /> : null}
            {userPosition ? <WaterDrawer position={userPosition} /> : null}
          </MapContainer>
        </Col>
      </Row>
      <pre>{JSON.stringify(userPosition)}</pre>
    </Container>
  );
}
