import { LatLngExpression, divIcon } from "leaflet";
import { Marker } from "react-leaflet/lib/Marker";

interface IUserPositionMarkerProps {
  position: LatLngExpression;
}

export function UserPositionMarker({ position }: IUserPositionMarkerProps) {
  const positionMarkerIcon = divIcon({
    html: '<i class="bi-geo-alt-fill"></i>',
    className: "position-marker-icon",
  });

  return <Marker position={position} icon={positionMarkerIcon}></Marker>;
}
