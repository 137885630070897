import { useMap } from "react-leaflet";
import { Position } from "../domain/Position";
import { UserPositionMarker } from "./UserPositionMarker";

interface ILocationDrawerProps {
  position: Position;
}

export function LocationDrawer({ position }: ILocationDrawerProps) {
  const map = useMap();

  if (position) {
    map.setView(position, 15);

    return <UserPositionMarker position={position} />;
  }

  return null;
}
